<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="mx-1" v-show="!isLoading">
      <registry-groups
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :ref="tableRef"
        :onlyActions="['edit']"
        @edit="goto"
        includeRowSelector
        @rowSelector="setPreferred"
        noInnerWidth
      >
      </registry-groups>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [ShortcutMixin, DetailTabAutofetchTableMixin],
  name: "Group",
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      items: [],
      repository: "registry_group",
      resource: "registry_groups",
      filterName: "filterRegistryGroupDetail",
      tableRef: "RegistryGroupDetailTableRef",

      fields: [
        {
          key: "rowSelector",
          label: "Princ.",
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "code",
          label: this.getDictionary("code", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          label: this.getDictionary("title", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "anagrafica",
          label: this.getDictionary("registry"),
          sortable: true,
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byRegistry: { id: this.resourceId },
      };
      return init;
    },
    // setPreferred(item) {
    //   // update registries pivot fields in two steps:
    //   // 1. set the actual primary group as not primary
    //   // 2. set the selected group as primary
    //   const tableRef = this.$refs[this.tableRef];
    //   this.isLoading = true;
    //   if (tableRef !== undefined) {
    //     let promises = [];
    //     let payload = {};
    //     // 1. find the group with primary Y and set to N
    //     let items = tableRef.items;
    //     if (items.length) {
    //       for (const item of items) {
    //         const index = item.registries.findIndex(
    //           (elem) => elem.id == this.resourceId
    //         );
    //         const pivot = item.registries[index].pivot;
    //         if (pivot.primary.value === "Y") {
    //           payload = {};
    //           payload[this.resourceId] = {
    //             primary: "N",
    //           };
    //           // console.debug(`group id: ${pivot.registry_group_id}, payload: ${JSON.stringify(payload)}`);
    //           let n = this.storePivotData(pivot.registry_group_id, payload);
    //           promises.push(n);
    //           break;
    //         }
    //       }
    //     }
    //     // 2. update selected group (item.id) with primary Y
    //     payload = {};
    //     payload[this.resourceId] = {
    //       primary: "Y",
    //     };
    //     // console.debug(`group id: ${row.id}, payload: ${JSON.stringify(payload)}`);
    //     let y = this.storePivotData(item.id, payload);
    //     promises.push(y);

    //     Promise.all(promises)
    //       .then(() => {
    //         this.$showSnackbar({
    //           preset: "success",
    //           text: `Azione Completata: Gruppo Principale impostato`,
    //         });
    //         this.isLoading = false;
    //         // salvo il filtro (byRegistry[id]=X)
    //         // tableRef.fetch().finally(() => {
    //         //   this.isLoading = false;
    //         // });
    //         this.onSearch(this.filterName);
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({
    //           preset: "error",
    //           text: `${errMsg}`,
    //         });
    //       });
    //   }
    // },
    setPreferred(item) {
      this.isLoading = true;
      // POST /api/registries/1/pivot/registry_group
      // {
      //     "registry_group": {
      //             "1": {
      //                   "primary": "N"
      //               },
      //               "2": {
      //                   "primary": "Y",
      //               }
      //     }
      // }
      let payload = {};
      // mando solo la modifica sul gruppo!
      payload[item.id] = {
        primary: "Y",
      };
      this.storePivotData(this.resourceId, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Gruppo Principale impostato`,
          });
          this.isLoading = false;
          // salvo il filtro (byRegistry[id]=X)
          // tableRef.fetch().finally(() => {
          //   this.isLoading = false;
          // });
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    goto(id) {
      this.shortcut(
        "registry_groups.edit",
        id,
        null,
        null,
        null,
        {
          /* fromShortcut: true */
        },
        [
          {
            text: "Portafoglio",
            path: "",
            tab: "",
            level: 0,
            disabled: true,
          },
          {
            text: "Gestione Portafoglio",
            path: "module.PFOLIO",
            tab: "",
            level: 1,
          },
          {
            text: "Gruppi",
            path: "module.PFOLIO",
            tab: "#RegistryGroups",
            level: 2,
          },
        ]
      );
    },
    // updatePivotData(id, payload) {
    //   const Repo = RepositoryFactory.get(this.repository);
    //   return Repo.pivot_update(id, "registry", {
    //     registry: payload,
    //   });
    // },
    // storePivotData(id, payload) {
    //   const Repo = RepositoryFactory.get(this.repository);
    //   return Repo.pivot_store(id, "registry", {
    //     registry: payload,
    //   });
    // },
    storePivotData(id, payload) {
      const Repo = RepositoryFactory.get("registry");
      return Repo.pivot_store(id, "registry_group", {
        registry_group: payload,
      });
    },
  },
  components: {
    BaseIcon,
    RegistryGroups: () => import("@/components/tables/RegistryGroups"),
  },
  props: {
    resourceId: Number,
  },
};
</script>
<style lang="scss" scoped>
:deep(.actions) {
  background-color: transparent !important;
}
</style>
