<template>
  <div>
    <div class="mb-1">
      <b-row>
        <b-col align="right">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAddCorrespondence"
            title="Crea Indirizzo"
            v-if="canVerb(resource, 'store')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </b-col>
      </b-row>
    </div>

    <table-correspondences
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noInnerWidth
      @destroy="onDelete"
      @create="onCreate"
      @edit="onEdit"
      :onlyActions="['edit', 'destroy']"
    >
      <!-- :onlyActions="['edit', 'destroy']" -->
      <!-- correspondence/:id/edit va in errore: non è possibile modificare
    TODO: la modale editCorrespondenceMoal è da completare -->
    </table-correspondences>
    <!-- </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay> -->

    <add-correspondence-modal
      :status_correspondence_allowed="correspondencesAllowed"
      @create="onCreate"
    ></add-correspondence-modal>

    <edit-correspondence-modal
      :ref="editModalRef"
      :status_correspondence_allowed="correspondencesAllowed"
      @update="onUpdate"
    ></edit-correspondence-modal>
  </div>
</template>

<script>
import TableCorrespondences from "@/components/tables/Correspondences";
import AddCorrespondenceModal from "@/components/modals/addCorrespondenceModal";
import EditCorrespondenceModal from "@/components/modals/editCorrespondenceModal";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [FormMixin, DetailTabAutofetchTableMixin],
  name: "Correspondences",
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterCorrespondenceDetail",
      repository: "correspondence",
      resource: "correspondences",
      tableRef: "CorrespondenceTableRef",
      editModalRef: "editCM",
      correspondenceId: null,
      items: [],
      fields: [
        {
          key: "status_correspondence.text",
          label: this.getDictionary("status_correspondence", "correspondence"),
        },
        {
          // key: "attributables.ADDR",
          key: "domicilio",
          label: this.getDictionary("domicilio", "correspondence"),
        },
        {
          key: "city",
          label: this.getDictionary("city", "correspondence"),
        },
        {
          key: "attributables.NAME2",
          label: this.getDictionary("attribute_NAME2_value", "correspondence"),
        },
        {
          key: "attributables.ZIPC",
          label: this.getDictionary("attribute_ZIPC_value", "correspondence"),
        },
      ],
      allowed: [],
      formLoaded: false,
      beEditForm: {},
      form: {
        country: null,
        province: null,
        zipcode: null,
        correspondence: {
          status_correspondence: null,
          attribute_ADDR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NAME2_value: null,
          registry: {},
        },
      },
    };
  },
  components: {
    TableCorrespondences,
    AddCorrespondenceModal,
    EditCorrespondenceModal,
  },
  props: {
    resourceId: Number,
  },
  methods: {
    initFilter() {
      let init = {
        byRegistry: { id: this.resourceId },
      };
      return init;
    },
    ...mapGetters("auth", {
      getCountries: "countries",
      getCouncils: "councils",
      getProvinces: "provinces",
    }),
    onAddCorrespondence() {
      this.correspondencesAllowed = this.getStatusCorrespondenceAllowed();
      this.$bvModal.show("addCorrespondenceModal");
    },
    getStatusCorrespondenceAllowed() {
      // 0: una e una sola Residenza / Sede Legale, sempre
      // 1: nessun o al più un solo Domicilio / Sede Amministrativa
      // 2: n Indirizzi Alternaivi, con n >= 0
      // devo poter creare indirizzi di tipo 2, e di tipo 1 se non già presenti
      let ret = [2];
      let all = this.$refs[this.tableRef].items.map(
        (item) => item.status_correspondence.value
      );
      if (!all.includes(1)) {
        ret.push(1);
      }
      return ret;
    },
    onCreate(form) {
      // devo lasciare address e city, se estero, togliere city2 e nor...
      // vedere AddressBookCorrespondencesMixin storeSection
      if (!form[this.repository].attribute_CITY2_value) {
        this.$delete(form[this.repository], "attribute_NOR_value");
        this.$delete(form[this.repository], "attribute_CITY2_value");
      }
      form[this.repository].registry = { [this.resourceId]: {} };
      this.store(this.repository, form[this.repository])
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Indirizzo creato`,
          });
          // this.$refs[this.tableRef].fetch();
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          console.error(error);
          this.showError(error);
        });
    },
    onEdit(id) {
      this.correspondenceId = id;
      this.$refs[this.editModalRef].isLoading = true;
      this.$refs[this.editModalRef]
        .fetchForm(this.correspondenceId)
        .then(() => {
          const cc = Object.values(this.$store.state.auth.councils);
          const cityId =
            this.$refs[this.editModalRef].form[this.repository]
              .attribute_CITY_value;
          if (
            !this.$refs[this.editModalRef].form[this.repository]
              .attribute_CITY2_value
          ) {
            if (
              !this.$refs[this.editModalRef].form[this.repository]
                .attribute_NOR_value
            ) {
              // imposta italia
              let country_id = this.getCountries().find(
                (c) =>
                  c.text == this.$refs[this.editModalRef].defaultCountryName
              ).value;
              this.$set(
                this.$refs[this.editModalRef].form[this.repository],
                "attribute_NOR_value",
                country_id
              );
              // const council = this.councils()().find(
              //   (council) => council.value == this.form[this.rep].attribute_CITY_value
              // );
              // this.$set(this.form, "province", council.province);
            }

            let provinceId;
            const council = cc.find((c) => c.id == cityId);
            if (council) {
              provinceId = council.province_id;
              if (provinceId) {
                this.$refs[this.editModalRef].optCouncils =
                  this.getCouncils()(provinceId);
              }
            }
            this.$refs[this.editModalRef].optProvinces = this.getProvinces()(
              this.$refs[this.editModalRef].form[this.repository]
                .attribute_NOR_value
            );
            this.$refs[this.editModalRef].form.province = provinceId;
          }
          let scId =
            this.$refs[this.editModalRef].form[this.repository]
              .status_correspondence;
          this.correspondencesAllowed = this.getStatusCorrespondenceAllowed();
          if (!this.correspondencesAllowed.includes(scId)) {
            this.correspondencesAllowed.push(scId);
          }
          this.$refs[this.editModalRef].isLoading = false;
          this.$bvModal.show("editCorrespondenceModal");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdate(form) {
      // devo lasciare address e city, se estero, togliere city2 e nor...
      // vedere AddressBookCorrespondencesMixin editSection
      if (!form[this.repository].attribute_CITY2_value) {
        this.$delete(form[this.repository], "attribute_NOR_value");
        this.$delete(form[this.repository], "attribute_CITY2_value");
      }
      form[this.repository].registry = { [this.resourceId]: {} };
      this.update(this.repository, this.correspondenceId, form[this.repository])
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Indirizzo aggiornato`,
          });
          // this.$refs[this.tableRef].fetch();
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          console.error(error.data);
          this.showError(error);
        });
    },
    showError(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    onDelete(id) {
      this.delete("correspondence", id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Indirizzo Eliminato`,
          });
          // this.$refs[this.tableRef].fetch();
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
  computed: {
    frm() {
      return this.form[this.repository];
    },
    showEdit() {
      return !this.$refs[this.editModalRef].isLoading;
    },
    correspondencesAllowed: {
      get() {
        return this.allowed;
      },
      set(value) {
        this.allowed = value;
      },
    },
  },
  beforeMount() {
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.formLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
