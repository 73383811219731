export const toLocaleCurrency = (value, locale = "it-IT", currency = "EUR") => {
  return value /*!isNaN(value)*/
    ? value.toLocaleString(locale, { style: "currency", currency: currency })
    : "";
};

export const sumBreakdowns = (array, field) => {
  let initialValue = 0;
  let sum = array.reduce(function(previousValue, currentValue) {
    return previousValue + currentValue[field].value;
  }, initialValue);
  return sum;
};

export const dataMappingPremium = (data) =>
  data.map((el) => {
    return {
      title: el.title,
      net: {
        value: 0,
        is_net: el.is_net.value,
      },
      tax: {
        value: 0,
        is_tax: el.is_tax.value,
      },
      id: el.id,
    };
  });

export const dataMappingProv = (data) =>
  data.map((el) => {
    return {
      title: el.title,
      take: {
        value: 0,
        is_take: "Y",
      },
      purchase: {
        value: 0,
        is_purchase: "Y",
      },
      id: el.id,
    };
  });

// module.exports = {
//   toLocaleCurrency,
//   sumBreakdowns,
//   dataMappingPremium,
//   dataMappingProv,
// };
