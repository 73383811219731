<template>
  <div class="mx-1">
    <table-claims
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      noActions
      noInnerWidth
      noInputPaginator
    >
    </table-claims>
  </div>
</template>

<script>
import TableClaims from "@/components/tables/Claims";
import { toLocaleCurrency } from "@/utils/strings";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [DetailTabAutofetchTableMixin],
  name: "Claims",
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterClaimDetail",
      repository: "claim",
      resource: "claims",
      tableRef: "ClaimTableRef",
      items: [],
      fields: [
        {
          key: "number",
          label: "Sinistro",
          sortable: true,
          sortKey: "byAttribute.number",
        },
        {
          key: "surveyor",
          label: this.getDictionary("surveyor", "claim"),
          sortable: false,
          sortKey: "byAttribute.surveyor",
        },
        {
          key: "settlement_expense",
          label: this.getDictionary("settlement_expense", "claim"),
          sortable: true,
          sortKey: "byAttribute.settlement_expense",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    TableClaims,
  },
  props: {
    resourceId: Number,
  },
  methods: {
    initFilter() {
      let init = {
        byInsurancePolicy: { id: this.resourceId },
      };
      return init;
    },
  },
};
</script>
