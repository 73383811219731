<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <book-entries
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :ref="tableRef"
          :hasChecksButtonGroup="false"
          :filterOn="{
            byRelations: [
              'byBroker',
              'byTask',
              'byInsurer',
              'byInsuranceAncillary',
              'byVariousAccounting',
            ],
          }"
          :onlyActions="['infomodal']"
          noInnerWidth
          sortField="book_date"
          noInputPaginator
          noPagination
          includeBottomRow
        ></book-entries>
      </div>

      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BookEntries from "@/components/tables/BookEntries";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";

export default {
  mixins: [DetailTabAutofetchTableMixin],
  components: {
    BaseIcon,
    BookEntries,
  },
  name: "Takings",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterBookEntriesByRegistry",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "BookEntriesByRegistryTableRef",
      isLoading: false,
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          sortKey: "book_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "effective_date",
          label: this.getDictionary("effective_date", "book_entry"),
          sortable: true,
          sortKey: "effective_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "insurer.formatted_title",
          label: this.getDictionary("insurer_id", "book_entry"),
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
        },
        {
          key: "branch",
          label: this.getDictionary("title", "risk_branch"),
          sortable: false,
        },
        {
          key: "product",
          label: this.getDictionary("insurance_risk"),
          sortable: false,
        },
        {
          key: "document_code",
          label: this.getDictionary("code", "book_tag"),
        },
        {
          key: "customer",
          label: this.getDictionary("registry"),
          sortable: false,
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          sortable: true,
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "agency_gross",
          label: this.getDictionary("Provv. Agenzia"),
          sortable: false,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "saler_gross",
          label: this.getDictionary("Provv. Produttore"),
          sortable: false,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
        },
        {
          key: "deferred",
          label: this.getDictionary("Sospeso"),
          tdClass: "text-center",
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byView: "book_entry",
        byRegistry: { id: this.resourceId },
      };
      return init;
    },
  },
  component: {
    BookEntries,
  },
};
</script>
