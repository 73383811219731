<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(anagrafica)="{ item }" v-if="items.length">
            <span v-for="(registry, i) in item.registries" :key="registry.id">
              <span
                v-if="
                  !Array.isArray(registry.attributables) &&
                  !registry.attributables.length
                "
                >{{
                  registry.status_registry.value === 1
                    ? registry.attributables.CNAM
                    : registry.attributables.SURN +
                      " " +
                      registry.attributables.NAME
                }}{{ i == item.registries.length - 1 ? "" : ", " }}</span
              >
            </span>
          </template>
          <template #cell(actionRow)="row" v-if="items.length">
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onChosen(row.item)"
              class="mt-1 mr-1"
              title="Aggiungi"
            >
              Aggiungi
            </b-button>
          </template>
          <template #cell(rowSelector)="row">
            <div class="action-buttons" v-if="!isPrimary(row.item)">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1 ml-auto"
                title="Imposta come Principale"
              >
                <b-icon icon="trophy-fill"></b-icon>
              </b-button>
            </div>
            <div class="action-buttons" v-else>
              <b-button
                size="sm"
                variant="lisaweb"
                disabled
                class="mt-1 mr-1 ml-auto"
                title="Principale"
              >
                <b-icon icon="trophy"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/wallet/registry-groups/details/General";
import BaseInputPagination from "@/components/form/BaseInputPagination";

export default {
  name: "PolicyGroupsTable",
  extends: template,
  data() {
    return {
      tabs: [{ name: "General", text: "Generale" }],
      selected: "General", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
    };
  },
  components: {
    BaseIcon,
    General,
    ButtonGroupTab,
    BaseInputPagination,
  },
  methods: {
    // highlight the row if primary is 'Y',
    // only when showing the registry_group index filtered with filterOn byRegistry
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.registries) return;
      // let registry_id = this.getRegistryId();
      // if (!registry_id) return;
      // return item.registries.find((r) => r.id == registry_id).pivot.primary
      //   .value === "Y"
      //   ? "table-success-lisaweb"
      //   : "";
      return this.isPrimary(item) ? "table-success-lisaweb" : "";
    },
    getRegistryId() {
      let registry_id;
      const filter = this.$parent.$parent.$data.filter;
      if (filter && Object.keys(filter).length) {
        registry_id = filter.byRegistry.id;
      }
      return registry_id;
    },
    isPrimary(item) {
      let found = item.registries.find((r) => r.id == this.getRegistryId());
      if (found) {
        return found.pivot.primary.value === "Y";
      }
      return false;
    },
    customized(item) {
      let c = {};
      c.gruppo = item.code;
      c.titolo = item.title;
      let array_ana = [];
      if (item.registries) {
        Object.keys(item.registries).forEach((i) => {
          let anagrafiche = item.registries[i];
          let anagrafica =
            anagrafiche.status_registry.value === 1
              ? anagrafiche.attributables.CNAM
              : anagrafiche.attributables.NAME +
                " " +
                anagrafiche.attributables.SURN;
          array_ana.push(anagrafica);
        });
        c.anagrafica = array_ana.join(", ");
      }

      let customAttributes = this.convertFromCustomAttributesToFields(
        this.customAttributes()(this.repository)
      );

      if (customAttributes) {
        Object.keys(customAttributes).forEach((i) => {
          let value = customAttributes[i].key.split(".");
          let label = customAttributes[i].label;
          c[label] = item.attributables[value[value.length - 1]]; // get always the last split element
        });
      }

      return c;
    },
  },
};
</script>
