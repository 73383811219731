<template>
  <b-modal
    id="addRelationModal"
    size="xl"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Associa </span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <b-form @submit.prevent="onSearch(filterName)">
      <div class="mt-2">
        <b-card class="filter">
          <b-row>
            <div class="col-md-6">
              <base-input
                name="RFRNAME"
                v-model="filter.byRegistry.title"
                type="text"
                label="Nominativo"
                placeholder="Inserisci un nominativo"
              />
            </div>
            <div class="col-md-6 align-self-center actions-wrapper">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-3
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>
          <b-collapse id="collapse-3" class="mt-2" v-model="visible">
            <b-row>
              <div class="col-md-6">
                <base-input
                  name="NINO"
                  type="text"
                  label="Codice Fiscale"
                  v-model="filter.byAttribute.NINO"
                  placeholder="Inserisci un codice fiscale"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  name="creg"
                  type="text"
                  label="Partita IVA"
                  v-model="filter.byCustom.CREG.value"
                  placeholder="Inserisci una partita IVA"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  name="salesman"
                  label="Produttore"
                  v-model="filter.byBroker.id"
                  :options="salesmen"
                  :multiple="false"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  name="localita"
                  label="Località"
                  v-model="filter.byCorrespondence.CITY"
                  placeholder="Digitare le lettere iniziali..."
                  :options="ajaxOptions"
                  @search-change="getAjaxOptions"
                  :isLoading="isLoadingAjax"
                />
              </div>
              <div class="col-md-12">
                <base-textarea
                  name="ADDR"
                  v-model="filter.byCustom.ADDR.value"
                  label="Indirizzo"
                  placeholder="Inserisci un indirizzo"
                />
              </div>
            </b-row>
          </b-collapse>
        </b-card>
      </div>
    </b-form>
    <table-registries
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{ byRelations: ['byCorrespondence'] }"
      :saveRelationBtn="saveRelationBtn"
      noInnerWidth
      noActions
      noCustom
      hasChecks
      noInputPaginator
    >
    </table-registries>
    <!-- :isCheckExclusive="isCheckRadio" -->
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      // isCheckRadio: true,
      saveRelationBtn: true,
      filter: this.initFilter(),
      filterName: "filterQuickSearchRelation",
      repository: "registry",
      resource: "registries",
      tableRef: "QuickSearchRelationTableRef",
      relativeTypes: [],
      visible: false,
      isLoadingAjax: false,
      ajaxOptions: [],
      salesmen: [],
      fields: [
        {
          key: "radio_check",
          label: "",
          sortable: false,
          // thClass: "no-check",
          class: "no-th-check",
        },
        {
          key: "attributables.SURN",
          label: "Cognome",
        },
        {
          key: "attributables.NAME",
          label: "Nome",
        },
        {
          key: "attributables.CNAM",
          label: "Ragione Sociale",
        },
        {
          key: "attributables.NINO",
          label: "Codice Fiscale",
        },
        {
          key: "attributables.CREG",
          label: "Partita IVA",
        },
        {
          key: "relative_types",
          label: "Relazioni",
          thStyle: {
            width: "250px",
          },
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
        },
      ],
    };
  },
  props: {
    excludedRegistryIds: Array,
  },
  components: {
    BaseInput,
    TableRegistries: () => import("@/components/tables/Registries"),
    BaseTextarea,
    BaseSelect,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          id: `!${this.excludedRegistryIds}`,
          CNAME: null,
          NINO: null,
        },
        byBroker: {
          id: null,
        },
        byRegistry: {
          title: null,
        },
        byCustom: {
          CREG: {
            value: null,
            likewise: 2,
            filter: "byAttribute",
          },
          ADDR: {
            value: null,
            likewise: 4,
            filter: "byCorrespondence",
          },
        },
        byCorrespondence: {
          CITY: null,
        },
      };
      return init;
    },
    onSearch(name) {
      // togliere il filterOn dalla table per inibire automatic fetch, e impostare in initFilter
      // ricordarsi di eliminare il filtro subito dopo aver fatto fetch
      this.filter.byAttribute.id = `!${this.excludedRegistryIds}`;
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        this.ajaxOptions = this.getCouncils()(null, query);
        this.isLoadingAjax = false;
      }
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getCouncils: "councils",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  mounted() {
    this.salesmen = this.getSalesmen();
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: auto;  unset or auto value is not ok */
  overflow: auto !important;
  max-height: 500px !important; /* prevent external container to scroll*/
  min-height: 100px !important;
}

:deep(.b-table-sticky-header > .table.b-table > thead > tr > th) {
  z-index: 7 !important;
}

:deep(.card-text .row .form-group) {
  margin-bottom: 0rem !important;
}

:deep(.multiselect__content-wrapper) {
  //   position: inherit !important;
  max-height: 165px !important;
}

:deep(div.table-responsive) {
  overflow: visible !important;
}

:deep(
    table:has(.multiselect--active) tbody tr:not(:has(.multiselect--active))
  ) {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

:deep(
    table:has(.multiselect__option--selected)
      tbody
      tr:not(:has(.multiselect__option--selected))
  ) {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

:deep(table tbody tr:has(.multiselect--actived)) {
  background: #eaeaea;
}

:deep(table tbody tr:has(.multiselect__option--selected)) {
  background: #eaeaea;
}

:deep(table tbody tr:not(:has(.multiselect__option--selected)) .check) {
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
:deep(th.no-th-check input[type="checkbox"]) {
  display: none;
}

:deep(th.no-th-check .custom-control) {
  min-height: 0;
  padding-left: 0;
  display: inline;
}

:deep(.no-th-check .form-group) {
  margin: 0;
}

:deep(th.no-th-check .custom-control-label:before) {
  display: none;
}

:deep(th.no-th-check .custom-control-label:after) {
  display: none;
}

.actions-wrapper {
  margin-top: 5px;
}
</style>
