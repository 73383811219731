<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="mx-1" v-show="!isLoading">
      <policy-groups
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :ref="tableRef"
        :onlyActions="['edit']"
        @edit="goto"
        includeRowSelector
        @rowSelector="setPreferred"
        noInnerWidth
      >
      </policy-groups>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import PolicyGroups from "@/components/tables/PolicyGroups";
import { mapActions } from "vuex";

export default {
  mixins: [ShortcutMixin, FormMixin, ShowMixin],
  name: "Group",
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      items: [],
      repository: "registry_group",
      resource: "registry_groups",
      filterName: "filterRegistryGroupDetail",
      tableRef: "RegistryGroupDetailTableRef",
      table: null,
      fields: [
        {
          key: "rowSelector",
          label: "Princ.",
          thStyle: {
            width: "40px",
          },
        },
        {
          key: "code",
          label: this.getDictionary("code", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          label: this.getDictionary("title", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "anagrafica",
          label: this.getDictionary("registry"),
          sortable: true,
        },
      ],
    };
  },
  methods: {
    initFilter() {
      let init = {
        byRegistry: { id: null },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.table
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    fetchForm() {
      // this.isLoading = true;
      // recuperare il registryId associato a questo resourceId (polizza)
      return this.fetchShowForm("insurance_policy", this.resourceId)
        .then((response) => {
          // console.log("show");
          // console.log(response.data.data);
          this.registryId = response.data.data.registry_id;
          // console.log("registryId: ", this.registryId);
          this.filter.byRegistry.id = this.registryId;
          this.onSearch(this.filterName);
          this.externalData = response.data.data;
          this.fetchEditForm("insurance_policy", this.resourceId)
            .then(() => {
              // this.initDetailCardFormData();
              this.initMandatoryFields("insurance_policy");
              // this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    setPreferred(item) {
      this.isLoading = true;
      let payload = {};
      // mando solo la modifica sul gruppo!
      payload[item.id] = {
        primary: "Y",
      };
      this.storePivotData(this.registryId, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Gruppo Principale impostato`,
          });
          this.isLoading = false;
          this.onSearch(this.filterName);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    goto(id) {
      this.shortcut(
        "registry_groups.edit",
        id,
        null,
        null,
        null,
        {
          /* fromShortcut: true */
        },
        [
          {
            text: "Portafoglio",
            path: "",
            tab: "",
            level: 0,
            disabled: true,
          },
          {
            text: "Gestione Portafoglio",
            path: "module.PFOLIO",
            tab: "",
            level: 1,
          },
          {
            text: "Gruppi",
            path: "module.PFOLIO",
            tab: "#RegistryGroups",
            level: 2,
          },
        ]
      );
    },
    storePivotData(id, payload) {
      const Repo = RepositoryFactory.get("registry");
      return Repo.pivot_store(id, "registry_group", {
        registry_group: payload,
      });
    },
  },
  components: {
    BaseIcon,
    PolicyGroups,
    // RegistryGroups: () => import("@/components/tables/RegistryGroups"),
  },
  props: {
    resourceId: Number,
  },
  mounted() {
    // this.isLoading = true;
    this.table = this.$refs[this.tableRef];
    this.isLoading = true;
    this.fetchForm().finally(() => {
      this.isLoading = false;
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.actions) {
  background-color: transparent !important;
}
</style>
