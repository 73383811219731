var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}])},[(!_vm.isLoading)?_c('div',[_c('book-entries',{ref:_vm.tableRef,attrs:{"fields":_vm.fields,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName,"hasChecksButtonGroup":false,"filterOn":{
          byRelations: [
            'byBroker',
            'byTask',
            'byInsurer',
            'byInsuranceAncillary',
            'byVariousAccounting',
          ],
        },"onlyActions":['infomodal'],"noInnerWidth":"","sortField":"book_date","noInputPaginator":"","noPagination":"","includeBottomRow":""}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }