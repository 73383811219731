<template>
  <div>
    <div class="mb-2">
      <h6>Polizze Vive</h6>
      <table-policies
        infomodalName="P1"
        :fields="fields"
        repository="insurance_policy"
        resource="insurance_policies"
        :filterName="filterName1"
        ref="InsurancePolicyDetailTableRef1"
        :filterOn="{
          byRelations: ['byDocument', 'byBroker', 'byTask'],
        }"
        @open_note="openNoteModal"
        @open_document="openDocumentModal"
        :onlyActions="['infomodal']"
        noInnerWidth
        noInputPaginator
        sortField="covered_at"
        includeBottomRow
        noPagination
      >
      </table-policies>
    </div>
    <div class="closed-insurance-policies">
      <h6>Polizze Estinte</h6>
      <table-policies
        infomodalName="P2"
        :fields="fields"
        repository="insurance_policy"
        resource="insurance_policies"
        :filterName="filterName2"
        ref="InsurancePolicyDetailTableRef2"
        :filterOn="{
          byRelations: ['byDocument', 'byBroker', 'byTask'],
        }"
        @open_note="openNoteModal"
        @open_document="openDocumentModal"
        :onlyActions="['infomodal']"
        noInnerWidth
        noInputPaginator
        sortField="expired_at"
        :descending="true"
        includeBottomRow
        noPagination
      >
      </table-policies>
    </div>
    <note-detail-modal :items="notes" id="noteDetailModalPolicy">
    </note-detail-modal>
    <document-detail-modal :items="documents" id="documentDetailModalPolicy">
    </document-detail-modal>
  </div>
</template>

<script>
import TablePolicies from "@/components/tables/Policies";
import NoteDetailModal from "@/components/modals/noteDetailModal";
import DocumentDetailModal from "@/components/modals/documentDetailModal";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { mapActions } from "vuex";
import { getDictionary } from "@/utils/dictionary";

export default {
  name: "InsurancePolicy",
  data() {
    return {
      notes: [],
      documents: [],
      filter: {
        filterInsurancePolicyDetail1: this.initFilter1(),
        filterInsurancePolicyDetail2: this.initFilter2(),
      },
      filterName1: "filterInsurancePolicyDetail1",
      filterName2: "filterInsurancePolicyDetail2",
      repository: "insurance_policy",
      resource: "insurance_policies",
      tableRef: {
        filterInsurancePolicyDetail1: "InsurancePolicyDetailTableRef1",
        filterInsurancePolicyDetail2: "InsurancePolicyDetailTableRef2",
      },
      fields: [
        {
          key: "insurer.title",
          label: this.getDictionary("title", "insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "number",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.number",
        },
        {
          key: "contraente",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "insurance_risk.risk_branch.title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: true,
          sortKey: "byRiskBranch.title",
        },
        {
          key: "title",
          label: this.getDictionary("title", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_policy"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "effective_at",
        },
        {
          key: "covered_at",
          label: this.getDictionary("covered_at", "insurance_policy"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "covered_at",
        },
        {
          key: "expired_at",
          label: this.getDictionary("expired_at", "insurance_policy"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "expired_at",
        },
        {
          key: "payment_splitting.text",
          label: this.getDictionary("payment_splitting", "insurance_policy"),
        },
        {
          key: "gross_premium",
          value: "gross_premium",
          label: this.getDictionary("gross_premium", "insurance_policy"),
          formatter: (value) => toLocaleCurrency(value),
          sortable: true,
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "gross_payment_splitting",
          value: (item) =>
            toLocaleCurrency(
              item.gross_premium *
                (12 / (parseInt(item.payment_splitting.value) || 12))
            ),
          label: this.getDictionary(
            "gross_payment_splitting",
            "insurance_policy"
          ),
          sortable: true,
          sortKey: "byAttribute.gross_premium",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "status_policy.text",
          label: this.getDictionary("status_policy", "insurance_policy"),
          sortable: true,
          sortKey: "byAttribute.status_policy",
        },
        {
          key: "broker",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "note_counter",
          label: this.getDictionary("note"),
        },
        {
          key: "document_counter",
          label: this.getDictionary("document"),
        },
      ],
    };
  },
  components: {
    TablePolicies,
    NoteDetailModal,
    DocumentDetailModal,
  },
  props: {
    resourceId: Number,
  },
  methods: {
    getDictionary,
    initFilter1() {
      let init = {
        byRegistry: { id: this.resourceId },
        byAttribute: { status_policy: [0, 1, 2] },
      };
      return init;
    },
    initFilter2() {
      let init = {
        byRegistry: { id: this.resourceId },
        byAttribute: { status_policy: [3, 4, 5, 6, 7, 8, 9, 10] },
      };
      return init;
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModalPolicy");
    },
    openDocumentModal(array) {
      this.documents = array;
      this.$bvModal.show("documentDetailModalPolicy");
    },
    onSearch(name) {
      let criteria = this.filter[name];
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef[name]]
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  mounted() {
    // this.removeFilterByName(this.filterName1);
    // this.removeFilterByName(this.filterName2);
    this.onSearch(this.filterName1);
    this.onSearch(this.filterName2);
  },
};
</script>

<style scoped>
.closed-insurance-policies {
  margin-top: 30px;
}
</style>
