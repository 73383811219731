<template>
  <b-modal
    :id="id"
    size="lg"
    class="h-75 d-inline-block"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="house"></b-icon>
        <span>Modifica indirizzo</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
        <b-btn
          @click="
            cancel();
            updateCorrespondence();
          "
          size="sm"
          variant="outline-lisaweb"
          >Salva</b-btn
        >
      </b-form-group>
    </template> -->
    <div class="mx-1">
      <b-overlay center :show="isLoading" rounded="sm">
        <div v-if="!isLoading">
          <validation-observer
            ref="observer"
            v-slot="{ handleSubmit, invalid }"
          >
            <b-form @submit.prevent="">
              <b-row>
                <div class="form-group col-md-6">
                  <base-input
                    vid="status_correspondence"
                    :name="beForm[rep].status_correspondence.label"
                    :label="beForm[rep].status_correspondence.label"
                    v-model="
                      beForm[rep].status_correspondence.options.find(
                        (option) =>
                          option.value == form[rep].status_correspondence
                      ).text
                    "
                    :rules="getRules('attribute_NAME2_value')"
                    disabled
                  />
                </div>
                <div class="form-group col-md-6">
                  <base-input
                    vid="attribute_NAME2_value"
                    :name="beForm[rep].attribute_NAME2_value.label"
                    :label="beForm[rep].attribute_NAME2_value.label"
                    v-model="form[rep].attribute_NAME2_value"
                    placeholder="Inserisci il nome alternativo "
                    :rules="getRules('attribute_NAME2_value')"
                  />
                </div>

                <div class="form-group col-md-4">
                  <base-select
                    vid="country"
                    name="country"
                    label="Nazione"
                    v-model="form[rep].attribute_NOR_value"
                    :options="optCountries"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @select="onSelectCountry"
                  />
                </div>
                <div class="form-group col-md-4" v-if="!isForeign">
                  <base-select
                    name="Provincia"
                    vid="province"
                    label="Provincia"
                    v-model="form.province"
                    :options="optProvinces"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @select="onSelectProvince"
                  />
                </div>
                <div class="form-group col-md-4" v-if="!isForeign">
                  <base-select
                    vid="attribute_CITY_value"
                    :name="beForm[rep].attribute_CITY_value.label"
                    :label="beForm[rep].attribute_CITY_value.label"
                    v-model="form[rep].attribute_CITY_value"
                    :options="optCouncils"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    :rules="getRules('attribute_CITY_value')"
                  />
                </div>
                <div class="form-group col-md-4" v-else>
                  <base-input
                    vid="attribute_CITY2_value"
                    :name="beForm[rep].attribute_CITY2_value.label"
                    :label="beForm[rep].attribute_CITY2_value.label"
                    v-model="form[rep].attribute_CITY2_value"
                    placeholder="Inserisci il comune estero "
                    :rules="getRules('attribute_CITY2_value')"
                  />
                </div>
                <div class="form-group col-md-4">
                  <base-input
                    vid="attribute_ZIPC_value"
                    :name="beForm[rep].attribute_ZIPC_value.label"
                    :label="beForm[rep].attribute_ZIPC_value.label"
                    v-model="form[rep].attribute_ZIPC_value"
                    placeholder="Inserisci il CAP"
                    :rules="getRules('attribute_ZIPC_value')"
                  />
                </div>
                <div class="form-group col-md-4">
                  <base-input
                    vid="attribute_ADDR_value"
                    :name="beForm[rep].attribute_ADDR_value.label"
                    :label="beForm[rep].attribute_ADDR_value.label"
                    v-model="form[rep].attribute_ADDR_value"
                    placeholder="Inserisci un indirizzo"
                    :rules="{ required: true }"
                  />
                </div>
              </b-row>
            </b-form>
            <b-button
              @click="handleSubmit(updateCorrespondence)"
              type="button"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="mt-2 float-right"
            >
              Salva
            </b-button>
            <!-- <b-button
              @click="
                cancel();
                updateCorrespondence();
              "
              size="sm"
              variant="outline-lisaweb"
              >Salva</b-button
            > -->
          </validation-observer>
        </div>
        <template #overlay>
          <div class="text-center">
            <base-icon name="loading" width="35" height="35" />
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
    </div>
  </b-modal>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import FormMixin from "@/mixins/FormMixin";
export default {
  mixins: [FormMixin],
  data() {
    return {
      repository: "correspondence",
      resource: "correspondences",
      ajaxOptions: [],
      isLoading: false,
      isLoadingAjax: false,
      showForm: false,
      defaultCountryName: "Italia",
      foreignCouncilName: "Comune estero",
      optCountries: null,
      optProvinces: null,
      optCouncils: null,
      form: {
        province: null,
        correspondence: {
          status_correspondence: null,
          attribute_ADDR_value: null,
          attribute_NOR_value: null,
          attribute_CITY_value: null,
          attribute_CITY2_value: null,
          attribute_NAME2_value: null,
          attribute_ZIPC_value: null,
        },
      },
    };
  },
  props: {
    id: {
      type: String,
      default: "editCorrespondenceModal",
    },
    resourceId: null,
    value: null,
    status_correspondence_allowed: {
      type: Array,
      default: () => [2],
    },
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  computed: {
    rep() {
      return this.repository;
    },
    isForeign() {
      if (!this.form[this.rep].attribute_NOR_value) return false;
      return (
        this.optCountries.find(
          (c) => c.value == this.form[this.rep].attribute_NOR_value
        ).text !== this.defaultCountryName
      );
    },
  },
  methods: {
    fetchForm(resourceId) {
      return this.fetchEditForm(this.repository, resourceId);
    },
    initDefaultFormValues() {
      // Indirizzo Alternativo
      // this.form[this.repository].status_correspondence = 2; //this.beForm[this.repository].status_correspondence.value;
      // this.form.country = this.optCountries.find(
      //   (c) => c.text == this.defaultCountryName
      // ).value;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapGetters("auth", ["countries", "provinces", "councils"]),
    onSelectCountry(selected) {
      this.form.province = null;
      this.form.council = null;
      this.optProvinces = this.provinces()(selected);
      this.optCouncils = [];
      if (!this.isForeign) {
        this.form[this.rep].attribute_CITY2_value = null;
      } else {
        this.form[this.rep].attribute_CITY_value = null;
      }
    },
    onSelectProvince(selected) {
      this.optCouncils = [];
      this.form[this.repository].attribute_CITY_value = null;
      this.optCouncils = this.councils()(selected);
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    updateCorrespondence() {
      if (this.isForeign) {
        let council = this.councils()().find(
          (c) => c.text == this.foreignCouncilName
        );
        if (council) {
          this.$set(
            this.form[this.repository],
            "attribute_CITY_value",
            council.value
          );
        }
      }
      this.$delete(this.form[this.repository], "agency_setup");
      this.$delete(this.form[this.repository], "claim");
      this.$delete(this.form[this.repository], "registry");
      this.$delete(this.form[this.repository], "broker");
      this.$bvModal.hide(`${this.id}`);

      this.$emit("update", this.form);
    },
  },
  beforeMount() {
    console.debug("beforeMount", this.form[this.rep]);
    this.optCountries = this.countries();
    this.optProvinces = this.provinces()(
      this.form[this.rep].attribute_NOR_value
    );
    this.optCouncils = [];
  },
  mounted() {},
};
</script>
